export const homeAbout = [
  {
    id: 1,
    cover: "https://media.istockphoto.com/id/938364708/vector/caucasian-physio-checking-the-leg-of-a-patient.jpg?s=612x612&w=0&k=20&c=YhQDf-s5dpzz3igys_XnjPZOPsolruJsu3ixCQPNqxc=",
    title: "Holistic Approach to Wellness",
    desc: "Addressing physical, emotional, and mental health holistically. ",
  },
  {
    id: 1,
    cover: "https://static.vecteezy.com/system/resources/previews/008/631/492/original/physiotherapist-or-rehabilitologist-doctor-rehabilitates-patient-flat-cartoon-illustration-physiotherapy-rehab-injury-recovery-and-healthcare-concept-free-vector.jpg",
    title: "Expertise You Can Trust",
    desc: "Backed by experienced professionals committed to your well-being.",
  },
  {
    id: 1,
    cover: "https://static.vecteezy.com/system/resources/previews/013/211/493/original/chiropractor-flat-cartoon-hand-drawn-templates-illustration-of-patient-in-physiotherapy-rehabilitation-with-osteopathy-specialist-natural-treatment-vector.jpg",
    title: "Mission",
    desc: "At West Henday Physio, our mission is to empower and heal.",
  },
]
export const awrapper = [
  {
    cover: "",
    data: "3000",
    title: "+ Clients",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "20",
    title: "+ Clinicians",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1000",
    title: "+ SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "30",
    title: "+ Services",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Private Assessment",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        name: "Physiotherapist will assess injuries and provide a treatment plan.",
        // totalTime: " 45 min - $115.00",
      },
    ],
    priceAll: "45 min",
    pricePer: "$115.00",
  },
  {
    id: 2,
    coursesName: "Private Double Body Treatment",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        name: "Physiotherapist will treat multiple body sites and go over treatment plan in the same session.",
        // totalTime: "30 min - $130.00",
      },
    ],
    priceAll: "30 min",
    pricePer: "$130.00",
  },
  {
    id: 3,
    // cover: "../images/courses/c2.png",
    coursesName: "Private Vertigo Treatment",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        name: "Physiotherapist will treat injuries with the treatment plan and incorporate exercises, heat packs, etc. ",
        // totalTime: "15 min - $120.00",
      },
    ],
    priceAll: "15 minh",
    pricePer: "$120.00",
  },
  // {
  //   id: 3,
  //   cover: "../images/courses/c3.png",
  //   coursesName: "HTML, CSS, and Javascript for Web Developers",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Saroj Nepal",
  //       totalTime: "50 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$50 All Course",
  //   pricePer: "$5 per month",
  // },
  // {
  //   id: 4,
  //   cover: "../images/courses/c4.png",
  //   coursesName: "Introducing to Programming with WordPress",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Prabin Thapa Mager",
  //       totalTime: "20 lectures (20 hrs)",
  //     },
  //   ],
  //   priceAll: "$30 All Course",
  //   pricePer: "$3 per month",
  // },
  // {
  //   id: 5,
  //   cover: "../images/courses/c5.png",
  //   coursesName: "Introducing to Programming with ReactJS",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "100 lectures (150 hrs)",
  //     },
  //   ],
  //   priceAll: "$300 All Course",
  //   pricePer: "$30 per month",
  // },
  // {
  //   id: 6,
  //   cover: "../images/courses/c6.png",
  //   coursesName: "Learn Frontend Programming Language",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "200 lectures (300 hrs)",
  //     },
  //   ],
  //   priceAll: "$500 All Course",
  //   pricePer: "$80 per month",
  // },
  // {
  //   id: 7,
  //   cover: "../images/courses/c7.png",
  //   coursesName: "Introducing to with HTML / CSS",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Price",
  //       totalTime: "20 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$20 All Course",
  //   pricePer: "$2 per month",
  // },
  // {
  //   id: 8,
  //   cover: "../images/courses/c8.png",
  //   coursesName: "Introducing to with JAVA",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Petter",
  //       totalTime: "80 lectures (200 hrs)",
  //     },
  //   ],
  //   priceAll: "$200 All Course",
  //   pricePer: "$40 per month",
  // },
  // {
  //   id: 9,
  //   cover: "../images/courses/c9.png",
  //   coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Petter",
  //       totalTime: "50 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$20 All Course",
  //   pricePer: "$3 per month",
  // },
]
export const online = [
  {
    cover: "./images/services/image1.png",
    // hoverCover: "./images/courses/online/o1.1",
    courseName: "Manual Therapy",
    course: "Know more",
    router: "/manual-service"
  },
  {
    cover: "../images/services/image2.png",
    // hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Acupunture",
    course: "Know more",
    router: "/accupuncture-service"

  },
  {
    cover: "../images/services/image3.png",
    // hoverCover: "./images/courses/online/o3.1.png",
    courseName: "MVA treatment",
    course: "Know more",
    router: "/mva-treatement-service"
  },
  {
    cover: "../images/services/image4.png",
    // hoverCover: "./images/courses/online/o4.1.png",
    courseName: "Concussion Management",
    course: "Know more",
    router: "/concussion-management-service"
  },
  {
    cover: "../images/services/image5.png",
    // hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Dry Needling",
    course: "Know more",
    router: "/dry-neddling"
  },
  {
    cover: "../images/services/image6.png",
    // hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Massage Therapy",
    course: "Know more",
    router: "/message-therapy"
  },
  {
    cover: "../images/services/image7.png",
    // hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Psychology",
    course: "Know more",
    router: "/psychology"
  },
  {
    cover: "../images/services/image8.png",
    // hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Pelvic Floor Physiotherapy",
    course: "Know more",
    router: "/pelvic-floor-physiotherapy-service"
  },
  {
    cover: "../images/services/image9.png",
    // hoverCover: "./images/courses/online/o4.1.png",
    courseName: "Sports Physiotherapy",
    course: "Know more",
    router: "/sports-physiotherapy-service"
  },
  {
    cover: "../images/services/image10.png",
    // hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Vestibular Rehab",
    course: "Know more",
    router: "/vestibular-rehab-service"
  },
  {
    cover: "../images/services/image11.png",
    // hoverCover: "./images/courses/online/o6.1.png",
    courseName: "WCB Physiotherapy",
    course: "Know more",
    router: "/wcb-physiotherapy"
  },
  {
    cover: "../images/services/image12.png",
    // hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Post Surgery",
    course: "Know more",
    router: "/post-surgery"
  },
  {
    cover: "../images/services/image13.png",
    // hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Laser Therapy",
    course: "Know more",
    router: "/laser-therapy"
  },
  {
    cover: "../images/services/image14.png",
    // hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Shockwave Therapy",
    course: "Know more",
    router: "/shockwave-therapy"
  },
]
export const team = [

  {
    cover: "./images/team/image2.png",
    name: "Joyce Joseph",
    work: "Women’s Health therapist at Henday Physio",
    content: "Joyce graduated from Dr. M.G.R. Medical University, India in 2003 with a Bachelor of Physiotherapy degree. Joyce has certifications in acupuncture, Women’s Health, chronic pain management, spinal manipulations, concussion and vestibular rehabilitation. She has advanced certifications in treating women’s health issues and pelvic health conditions. Joyce is passionate about helping people to reduce pain, improve mobility and strength, recover from their injuries and return to activities they love.She loves to understand the background of the condition and strives to identify the root cause of the pain.Joyce feels patient education is the main factor in recovery, uses manual therapy skills and customized home exercise program tailored to her clients’ needs.She enjoys treating sports injuries, motor vehicle injuries, work- related injuries, chronic pain, dizziness and vertigo, urinary incontinence, pelvic organ prolapse and other pelvic health conditions.When Joyce is not working, she enjoys spending time with her family.To book an appointment with Joyce, please call Henday Innovation Physio at 587 - 873 - 8808!"
  },
  {
    cover: "./images/team/image4.png",
      name: "Pankaj Mudgal",
        work: "international graduate from India with a Masters in Neurology",
        content:"Pankaj Mudgal is an international graduate from India with a Masters in Neurology, completed in 2009. With over a decade of experience, he has a vast knowledge in treating sports injuries, MSK injuries, chronic pain, vertigo and concussion. He uses dry needling and acupuncture in his treatments, Pankaj is fluent in English, Hindi, Punjabi, and Urdu and has experience treating people from different cultural backgrounds. He connects with his patients and stresses on diagnosis. In his free time, Pankau enjoys playing badminton and volleyball, as well as hiking, camping and swimming. To book an appointment with Pankaj, please call Henday Innovation Physio at 587-873-8808!"
    },
  {
    cover: "./images/team/image10.png",
    name: "Hemal Penari",
    work: "Physical Therapist at Henday  Physio",
    content: "Hemal completed his Bachelor's in Physiotherapy in 2008 and his Master's in Physiotherapy in 2011. He completed the Physical Therapy Bridging Certificate program at the University of Alberta to integrate with physiotherapy practices in Canada. He has more than 12 years of experience in Physiotherapy, teaching and clinical practice. Hemal has completed his Functional Dry Needling/ IMS and Vestibular Rehabilitation certification. He has constantly been updating his skills and is interested in completing courses in Manipulation and concussion.Hemal enjoys getting to know his patients and creates custom treatment plans along with home exercise programs to give them the power to control their bodies and movement. He uses various manual therapy techniques, electrical modalities, and customized exercise plans to achieve client-centred goals. He also has a special interest in the rehabilitation of neck and back injuries.In his free time, Hemal enjoys travelling and reading.To book an appointment with Hemal, call Henday Innovation Physio at 587-873-8808!"
  },
 

{
  cover: "./images/team/image3.png",
    name: "Kevin Button",
      work: "postgraduate training in manual therapy",
      content:"Kevin Button graduated from the University of Alberta with a Bachelor of Science in Physical Therapy in 1987. Kevin has taken extensive postgraduate training in manual therapy and has obtained intermediate and Advanced Diplomas in Manipulative Therapy. Kevin has extensive experience treating sports injuries, injuries arising from motor vehicle accident, and work-related accidents. Kevin has a special interest in hockey injuries. Kevin is passionate about rehabilitating and educating his patients. Kevin has extensive knowledge in designing injury and sport-specific exercises programs to help in your rehabilitation and injury prevention. In the past, Kevin has working with Edmonton Eskimo Football Club. In 1998, he finished the Canadian Ironman after completing several triathlons in the preceding 5 years. Current recreational pursuits with his family include golfing, walking and strength training. Kevins enjoyment of active living and exercises is a great asset to help empower his patients to enjoy exercises in their rehabilitation. To book an appointment with Kevin, please call Henday Innovation Physio at 587-873-8808!"
  },
 

  {
    cover: "./images/team/image1.png",
    name: "Saadiya Ali",
    work: "Provisional Psychologist ",
    content: "Saadiya graduated from Athabasca University with a Master of Counselling degree specialization in Counselling Psychology in 2020. She is an accredited Registered Provisional Psychologist. She focuses on the therapeutic working alliance in therapy and utilizes evidence-based therapeutic modalities such as Solution-Focused Therapy, Cognitive-Behavioral Therapy, and Acceptance and Commitment Therapy.Saadiya works collaboratively with clients and focuses on creating a non-judgmental space, so her clients can feel safe, understood, and empowered to reach their goals. Saadiya is Multilingual, so sessions can be in English, Punjabi, Urdu, or Hindi upon client request.A central tenant to Saadiya’s approach in counselling is empowerment, where she supports her clients to empower themselves and regain independence and control over their lives. She specializes in working with Post-Injury, Short-Term and Long-Term Disability, Chronic Pain, Depression, Stress, Anxiety, and Trauma related to Motor Vehicle Accidents.In her free time, Saadiya enjoys travelling and spending quality time with her friends and family.Visit our website to book an appointment with Saadiya or call Henday Innovation Physio today!"
  },
// {
//   cover: "./images/team/image5.png",
//     name: "Sarah Chernik",
//       work: "Registered Massage Therapist at Henday Physio",
//       content:"Sarah Lynn Chernik graduated from MH Vicars School of Massage Therapy (2200-hour program) and is a member of CMMOTA (Canadian Massage and Manual Osteopathic Therapists Association). During her schooling, she enjoyed learning about a variety of approaches and techniques, including Swedish, therapeutic/ deep tissue, myofascial release, manual lymphatic drainage for edema for the limbs, neck and face, non-Swedish, trigger point, joint play, basic prenatal, sports massage, chair massage and hydrotherapy. Sarah takes pride in being an inclusive and body-positive RMT and strongly believes in a holistic approach to health. She strives to create a respectful massage experience where the therapist and client work together toward achieving the client’s therapeutic or relaxation goals.Sarah has been active all her life, including hobbies of basketball and fencing in her youth and a life-long passion for dance. A true geek at heart, Sarah also enjoys the creative challenge of cosplay (costuming for fun).To book an appointment with Sarah Chernik, please call Henday Innovation Physio at 587-873-8808!"
//   },
  {
    cover: "./images/team/image12.png",
    name: "Tina Kelepouris",
    work: "Massage Therapist ",
    content: "Tina Kelepouris has received her Massage Therapy Diploma from Grant MacEwan University in 2000 with 2200 hours. She  specializes in therapeutic/relaxation/sports/prenatal/manual lymphatic drainage/and cupping massage methods. She is passionate about working with people of all ages and cares deeply about helping people feel great! She has also been a Reiki Master since 2018. In her spare time, she enjoys spending time in nature/with family/and her rescue dog.She has also been volunteering with a Pit Bull rescue for over 10 years."
  },
  {
    cover: "./images/team/image11.png",
    name: "John Darrel",
    work: "Massage Therapist ",
    content: "John Darrel is passionate about helping people move, feel and function better. He can treat a variety of different issues ranging from specific acute and chronic musculoskeletal conditions to wellness and stress related issues. With his experience working at multidisciplinary clinics, John Darrel enjoys treating clients with issues such as work related stress, tension headaches, TMJ dysfunction, carpal tunnel syndrome and postural , sciatic nerve symptoms and other conditions that are prevalent to the general population. His modalities include different types of manual therapy such as Myofascial Release via Graston Tools, Deep Tissue Therapy, General Swedish Massage, Trigger Point Release and Remedial Exercises. He can also preform Cupping Therapy, Hot Stone Massage and Pre/Post Natal Massage.John Darrel is proud to help people with variety of massage therapy related dysfunctions achieve their goals. He believes there is nothing more rewarding than seeing a clients progression at the beginning with their pain and tension reduced and being able to move within their normal limits pain-free."
  },
  {
  cover: "./images/team/image6.png",
  name: "Korinne",
    work: "Aide",
    content:""
},
{
cover: "./images/team/image7.png",
name: "Fathima",
  work: "Administrative Assistant",
  content:""
},
{
  cover: "./images/team/image8.png",
  name: "Kaylie",
    work: "Administrative Assistant/Aide",
    content:""
  },
  {
    cover: "./images/team/image9.png",
    name: "Keerthi",
      work: "Aide",
      content:""
    },
 
]
export const price = [
  {
    name: "Private Seniors Treatment (65+)",
    price: "75.00 - 15 Min",
    desc: " Private Senior Physiotherapy Treatment - (15-30min) Physiotherapist will treat injures as outlined in the treatment plan with combined use of exercises, heat packs, etc. ",
  },
  {
    name: "Private Vertigo/Concussion Treatment",
    price: "120.00 - 15 Min",
    desc: "follow up visits for MVA patients who have completed the 21 in protocol visits",
  },
  {
    name: "Private Seniors Treatment (65+)",
    price: "75.00 - 15 Min",
    desc: " Private Senior Physiotherapy Treatment - (15-30min) Physiotherapist will treat injures as outlined in the treatment plan with combined use of exercises, heat packs, etc. ",
  },
  {
    name: "Private Vertigo/Concussion Treatment",
    price: "120.00 - 15 Min",
    desc: "follow up visits for MVA patients who have completed the 21 in protocol visits",
  },
]
export const faq = [
  {
    title: "What should I do after suffering a concussion?",
    desc: "After a concussion, physical and cognitive rest is initially recommended to ease symptoms and promote recovery. Current guidelines suggest gradual return to activity while staying below symptom exacerbation levels. Persistent symptoms may require specialized treatment and collaborative care with healthcare professionals.",
  },
  {
    title: "What injuries can be treated after a Motor Vehicle Accident?",
    desc: "Common injuries from MVAs include concussion, whiplash, shoulder and back injuries, neck pain, and radiating arm or leg pain. Our treatment approach includes manual therapy, exercise prescription, education, and a range of specialized treatments to address each individual's needs and facilitate a safe and effective recovery process.",
  },
  {
    title: "What conditions can Manual Therapy treat?",
    desc: "Manual Therapy is effective for a wide range of conditions, including back pain, neck pain, joint stiffness, sports injuries, and post-operative rehabilitation. It can benefit anyone seeking improved mobility and pain relief.",
  },
  {
    title: "What is Dry Needling and how does it work?",
    desc: "Dry needling is a modern treatment for muscular pain, involving the insertion of fine filiform needles into trigger points in muscles to release knots and relieve pain and spasms. The technique promotes relaxation and facilitates natural healing processes, providing targeted relief for various musculoskeletal conditions.",
  },
]
export const blog = [
  {
    id: 1,

    title: "Our Vision",
    desc: "Our vision is to become the integrated rehabilitation center of choice in West Edmonton, utilizing wide ranging professional resoucres to create a healthy and happy commmunity",
  },
  {
    id: 2,

    title: "Our Mission",
    desc: "Our mission is to deliver healthcare that empowers and heals and where knowledge meets clinical experience that is delivered in a caring, compassionate and respectful manner.",
  },
  {
    id: 3,

    title: "Core Values",
    desc: "Our core values capture the essence of our rehabilitaion center's principles and beliefs. They emphasize the importance of patient-centered, holistic and ethical care and are captured by the acronym HEAL.",
  },

]
export const testimonal = [
  {
    id: 1,
    name: "Sadiqa Hashim",
    desc: "Hi. I highly recommend this place, especially Joyce. She is brilliant and she knows how to heal your pain. She is very nice and sweet.They have very good customer service too.",
    cover: "./images/testo/image1.png",
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    desc: "Over all, it was a a very positive experience the months of physiotherapy and massage I received. The customer service is sharp and friendly, the two physiotherapists that worked on my lower back really cared and I improved allot to a point of relief and joy to have the pain going away and mobility I did not experience for a long time.",
    cover: "./images/testo/image2.png",
  },
  {
    id: 3,
    name: "Michelle Comeau",
    desc: "Everyone here is always so friendly and the Physical Therapists are so knowledgeable. My children and I have had great treatments here and I would highly recommend them.",
    cover: "./images/testo/image.png",
  },
  {
    id: 4,
    name: "Monique Sawchuk",
    desc: "I had recently injured my back at work & I was sent for physiotherapy so I chose Innovation & got Kevin Button. He assessed my injury & gave me exercises and manual therapy which helped my injury he is a very good physiotherapist would definitely recommend.",
    cover: "./images/testo/image.png",
  },
  {
    id: 5,
    name: "",
    desc: "",
    cover: "./images/testo/image.png",
  },
  {
    id: 6,
    name: "",
    desc: "",
    cover: "./images/testo/image.png",
  },
  {
    id: 7,
    name: "",
    desc: "",
    cover: "./images/testo/image.png",
  },
  {
    id: 8,
    name: "",
    desc: "",
    cover: "./images/testo/image.png",
  },
]

export const aboutNav = [
  {
    name: "Our Vision",
    router: "/ourvision",
  },
  {
    name: "Our Mission",
    router: "/ourmission",
  },
  {
    name: "Core values",
    router: "/corevalue",
  },
  {
    name: "Our Team",
    router: "/team",
  },
]

export const AboutFaq = [
  {
    title: "What is Pelvic Floor Physiotherapy? ",
    desc: "Pelvic Health Physiotherapy is an evidence-based specialized form of physiotherapy that focuses on the muscles, ligaments and tissues in the pelvic region of the body. Pelvic Health Physiotherapy aims to improve the function and strength of these muscles and alleviate pain or discomfort in the pelvic area. Our practices are based on gold-standard research and allow our physiotherapists to be able to evaluate the condition of your pelvic floor and create a plan of care for ongoing treatment.",
  },
  {
    title: "What is Shockwave Therapy?",
    desc: "Shockwave therapy is a non-invasive treatment that involves creating a series of low energy acoustic wave pulsations that are directly applied to an injury through a person’s skin via a gel medium. The concept and technology originally evolved from the discovery that focused sound waves could break down kidney and gallstones. Generated shockwaves have proven successful in several scientific studies for the treatment of chronic conditions. Shockwave therapy is its own treatment for a lingering injury, or pain resulting from illness. You don’t need painkillers with it - the purpose of the therapy is to trigger the body’s own natural healing response. Many people report that their pain is reduced, and mobility improved after the first treatment.",
  },
  {
    title: "What is High Intensity Laser Therapy? ",
    desc: "Laser Therapy is the application of red and infrared light over injuries to improve soft tissue healing resulting in relief if acute and chronic conditions. Laser therapy uses monochromatic light emission from a high intensity super luminous diode to treat musculoskeletal injuries, chronic and degenerative conditions to heal wounds. The light source is placed in contact with the skin allowing the photon energy to penetrate the soft tissue, where it interacts with various intracellular biomolecules resulting in the restoration of normal cell functions and enhancement of the body’s healing processes.",
  },
  {
    title: "What is Manual Physiotherapy?",
    desc: "Manual therapy is a field of physiotherapy. It utilizes manual and sometimes mechanical-assisted techniques to help relieve pain and increase mobility in patients suffering from musculoskeletal, soft tissue, or joint discomfort. Dating back as far as the 400s BC, manual therapy has a long history as an effective physiotherapy technique. It remains one of the most widely used forms of physiotherapy today. Unlike physiotherapy, which focuses on rebuilding and strengthening muscles, manual therapy treats soft tissue, joint, and muscle pain. While the two techniques are often confused or used interchangeably, physiotherapy and manual therapy are not the same. However, it is not a case of one being preferred over the other. Both techniques have their place and are often used together as part of a larger rehabilitation plan.",
  },
  {
    title: "What is Physiotherapy? ",
    desc: "Physiotherapy (also known as Physical Therapy) is the art and science of restoring function, movement, and wellness to a person in their environment. Physiotherapists (or physical therapists) are health care professionals with graduate degrees in physiotherapy, who provide assessment and treatment to address these dysfunctions. They may commonly have high levels of expertise in specific areas of rehabilitation including musculoskeletal, neurological, pelvic health, post-partum, pediatric and/or geriatric health. Individuals usually seek help from a physical therapist when they have pain, weakness, impaired movement, are recovering from an injury or surgery. They may have had an acute or chronic medical illness (e.g. heart attack, stroke) that necessitates recovery of strength, movement, and a reintegration back to activities of daily living.",
  },
  {
    title: "What is Psychology? ",
    desc: "Psychological therapy, psychotherapy, or “talk therapy” is the use of psychology methods to help you change your thoughts, beliefs and behaviours to overcome life’s problems. Psychotherapy seeks to improve your mental health and well-being, resolve behaviours, beliefs, compulsions, thoughts and emotions, as well as to improve your relationships.",
  },
]