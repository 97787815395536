import React from "react"
import Back from "../common/back/Back"
import Awrapper from "../about/Awrapper"
import "../about/about.css"
import "./ourmission.css"
import Our from "../../image/ourmissions.jpg"

const OurMission = ({backgroundImage}) => {
  return (
    <>
      <Back title='Our Mission' backgroundImage={backgroundImage} />
      <section className='team padding'>
        <div className='container'>
        <div className='text-content'>
        <h1>Our Mission</h1>

        <p>
   <b>“Physiotherapy is the first step for feeling better, moving better, and being better.”</b> <br/>

Our primary goal at the clinic is to provide patients with the best care and support they can receive while navigating rehab. Our team is committed to working with our patients to get them back to pre-injury level and back to enjoying life. What is critical in this process is establishing goals and identifiable outcomes and, providing patients with the tools to be independent and confident in their return to activity rather than coming dependant on our clinical team.</p>
          </div>
          <div className='image-content'>
            <img src={Our} alt='Our Mission' />
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default OurMission
